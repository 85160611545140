@font-face {
    font-family: 'Courier New';
    src: url('./assets/CourierNewPS-BoldMT.woff2') format('woff2'),
        url('./assets/CourierNewPS-BoldMT.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Courier New';
    src: url('./assets/CourierNewPSMT.woff2') format('woff2'),
        url('./assets/CourierNewPSMT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IQOS';
    src: url('./assets/IQOS-Regular.woff2') format('woff2'),
        url('./assets/IQOS-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



@tailwind base;
@tailwind components;
@tailwind utilities;
.App{
  width: 400px;
}
body{background: #EAE3D8; font-family: 'Courier New';}
header{
  background: #34303D;
}
h3{
  font-size: 13px;
      font-weight: bold;
  line-height: 1;;
}
h4{
      font-weight: bold;
  font-size: 13px;
  line-height: 1;;

}
p{
  font-size: 13px;
  line-height: 1;;


}
.display-info *{
  font-size: 13px;
  line-height: 1;
  white-space: pre-line;
}
.iq{
      font-family: 'IQOS';
  font-size: 20px;
  color:#41C0C7;
  padding-bottom:20px;
}
.popup-data label {
color:#fff;
margin-right: 10px;
width:100px;
float: left;
font-size: 20px;
}
.popup-data div{
  display:flex;
  align-items: center;
  margin-bottom: 20px;;
}
.popup-data input{
  border:0;
  width:  calc(100% - 100px);
  margin-left: 20px;;
  background:transparent;
  border-bottom: 2px solid #fff;
  font-size: 20px;
  color:#fff;
}
.popup-data div.rules {
  font-size: 14px;
  display: block;
  color: #fff;;
}
.rules a {
color: #43C0C7;
}